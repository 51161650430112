import './App.css';
import React, { useEffect } from 'react';
import FAQ from './components/faq';
import Hero from "./components/hero";
import Sponsor from "./components/sponsor";
// import Prizes from "./components/prizes";
// import { Sparkle } from './components/sparkles.js';
import Officer from "./components/officer.js"
import Footer from "./components/footer";
import AboutUs from "./components/aboutus";
import NoiseCanvas from "./components/utilis/NoiseCanvas";


function App() {
  return (
    <div id="App" className="App">
      <NoiseCanvas />
        <Hero/>
      <header className="App-header">
        {/* <AboutUs/> */}
        {/* <FAQ/> */}
        {/* <Sponsor/> */}
        {/* <Prizes/> */}
        <Officer/>
        <Footer/>
      </header>
    </div>
  );
}

export default App;
