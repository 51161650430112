import React, {useState, useEffect} from "react";
import './countdown.css';


const COUNTDOWN_TARGET = new Date("2024-10-25T16:59:59")

const getTimeLeft = () => {
    const totalTimeLeft = COUNTDOWN_TARGET - new Date();
    const days = Math.floor(totalTimeLeft / (1000 * 60 * 60 * 24));
    const hours = Math.floor((totalTimeLeft / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((totalTimeLeft / (1000 * 60)) % 60);
    const seconds = Math.floor((totalTimeLeft / 1000) % 60);
    return { days, hours, minutes, seconds};
}

const Countdown = () => {
  const [timeLeft, setTimeLeft] = useState(() => getTimeLeft());


  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(getTimeLeft());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);


  return (
      <div className="countdown">
          <h2>
          <div className="content">
              
              <div className="box">
                  <div className="value">
                      <span>{timeLeft.days}</span>
                  </div>
                  <span className="labler"> days </span>
              </div>
              <div className="box">
                  <div className="value">
                      <span>{timeLeft.hours}</span>
                  </div>
                  <span className="labler"> hours </span>
              </div>
              <div className="box">
                  <div className="value">
                      <span>{timeLeft.minutes}</span>
                  </div>
                  <span className="labler"> minutes </span>
              </div>
              <div className="box">
                  <div className="value">
                      <span>{timeLeft.seconds}</span>
                  </div>
                  <span className="labler"> seconds </span>
              </div>
          </div>
          </h2>
      </div>
  )
}

export default Countdown;