import "./hero.css";
import logo from './img/logo.svg';
import Countdown from './countdown.js';
import herobutton from './img/herobutton.png'


function Hero() {
    return (
        <div className='hero'>
            <div className="hackathon-date">
                <h1>Important Update: CodeRed Astra 2024</h1>
                <h3>CodeRED Astra will no longer take place as planned on October 12th.<br></br><br></br>
                Looking ahead to next year? Fill out the <a href="https://docs.google.com/forms/d/e/1FAIpQLSd1kzZGwgLD_XxJmqcx--gBd5jxCVzltslRt9DlsNdcya4iKw/viewform" target="_blank" rel="noopener noreferrer">CodeRed 2025 Interest Form</a> to stay updated and get involved with next year’s hackathon!<br></br><br></br> UH students: Instead of the hackathon, we’re excited to present <a href="https://docs.google.com/forms/d/e/1FAIpQLScMfnUMK42mmrgk-h4GUZ9C4vLazusen_3ZnP1wtAw2S-dWXw/viewform" target="_blank" rel="noopener noreferrer">Red Talks</a>, a series of student-led lightning talks where you can showcase your creativity, talent, and skills. If you're interested in presenting, sign up using the <a href="https://docs.google.com/forms/d/e/1FAIpQLScMfnUMK42mmrgk-h4GUZ9C4vLazusen_3ZnP1wtAw2S-dWXw/viewform" target="_blank" rel="noopener noreferrer">Red Talks Sign-Up Form</a> to share your ideas with fellow students and industry leaders.</h3>
            </div>
                {/* <div className="button2">
                    <a href="https://www.youtube.com/watch?v=PKocF-P4Phs" target="_blank" rel="noopener noreferrer">
                        <div className="text-box">
                            <h4>Join our discord!</h4>
                        </div>
                        <img src={herobutton} className="discordbutton" alt="Join Discord" />
                    </a>
                </div> */}
            </div>
    );
}
// function Hero() {
//     const hackathonDate = 'October 25 - 26'; // Replace with your actual hackathon date

//     return (
//         <div className='hero'>
//             <img src={logo} className="App-logo" alt="Hackathon Logo" />
//             <Countdown/>
//             <div className="hackathon-date">
//                 <h3>{hackathonDate}</h3>
//             </div>
//             <div className="ButtonLinks">
//                 <div className="button2">
//                     <a href="https://forms.gle/ztTdJmMcoLUSTWN57" target="_blank" rel="noopener noreferrer scale-box">
//                         <div className="text-box">
//                             <h4>Apply Now!</h4>
//                         </div>
//                         <img src={herobutton} alt="Apply Now" />
//                     </a>
//                 </div>
//                 {/* <div className="button2">
//                     <a href="https://www.youtube.com/watch?v=PKocF-P4Phs" target="_blank" rel="noopener noreferrer">
//                         <div className="text-box">
//                             <h4>Join our discord!</h4>
//                         </div>
//                         <img src={herobutton} className="discordbutton" alt="Join Discord" />
//                     </a>
//                 </div> */}
//             </div>
//         </div>
//     );
// }


// function Hero() {
//     return(
//         <div className='hero'>
//             <img src={logo} className="App-logo" alt="" />
//                 <Countdown/>
//             <div className="ButtonLinks">
//                 <div className="button2">
//                     <a href="https://forms.gle/ztTdJmMcoLUSTWN57" target="_blank">
//                         <div class="text-box">
//                             <h4>Apply Now!</h4>
//                         </div>
//                         <img src={herobutton} className="registerbutton" alt=""/>
//                     </a>
//                 </div>
//                 {/* <div className="button2">
//                     <a href="https://www.youtube.com/watch?v=PKocF-P4Phs" target="_blank">
//                         <div class="text-box">
//                             <h4>Join our discord!</h4>
//                         </div>
//                         <img src={herobutton} className="discordbutton" alt=""/>
//                     </a>
//                 </div> */}
//             </div>
//         </div>
//     );
// }

// the discord button can be added in later as an announcement

export default Hero;